import {useEffect, useState} from 'react';
import { api } from 'dicomweb-client';
import dcmjs from 'dcmjs';
import fakeReferringPhysicianNameList from './fakeReferringPhysicianNameList.json';

const naturalizeDataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset;

function withInstances(WrappedComponent){
  return function WithInstances (props){
    const [instances,setInstances] = useState();
    useEffect(()=>{
      fetch('https://d1omjy2me5cr0p.cloudfront.net/get_token')
        .then(resp=>resp.json())
        .then(token=>{
          const client = new api.DICOMwebClient({
            url: process.env.REACT_APP_API_BASE_URL,
            qidoURLPrefix: process.env.REACT_APP_QIDO_ROOT,
            wadoURLPrefix: process.env.REACT_APP_WADO_ROOT,
            headers: {
              'Authorization': `Bearer ${token.value}`,
            }
          });
          client.searchForStudies()
            .then(studies => {
              Promise.all(studies.map(study => {
                const {StudyInstanceUID: studyInstanceUID} = naturalizeDataset(study);
                return client.searchForSeries({
                  studyInstanceUID
                })
                  .then(series => {
                    return Promise.all(series.map(series => {
                      const {SeriesInstanceUID: seriesInstanceUID} = naturalizeDataset(series);
                      return client.searchForInstances({
                        studyInstanceUID,
                        seriesInstanceUID,
                      })
                        .then(instances => {
                          return Promise.all(instances.map(instance => {
                            const {SOPInstanceUID: sopInstanceUID} = naturalizeDataset(instance);
                            return client.retrieveInstanceMetadata({
                              studyInstanceUID,
                              seriesInstanceUID,
                              sopInstanceUID,
                            })
                              .then(result => {
                                return result.map(metadata => {
                                  const dataset = naturalizeDataset(metadata);
                                  if(fakeReferringPhysicianNameList[dataset.SOPInstanceUID] && Array.isArray(dataset.ReferringPhysicianName) && !dataset.ReferringPhysicianName.length){
                                    dataset.ReferringPhysicianName = fakeReferringPhysicianNameList[dataset.SOPInstanceUID];
                                  }
                                  return dataset;
                                });
                              });
                          }));
                        });
                    }));
                  });
              }))
                .then(result=>{
                  setInstances(result.flat(3));
                });
            });
        });
    },[]);

    return <WrappedComponent instances={instances} {...props}/>
  }
}

export default withInstances;