import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TableSortLabel,
  TablePagination,
  Autocomplete,
  TextField,
  Grid, TableContainer,
  MenuItem,
  Stack, Box,
  Chip,
  Badge,
  ListItemText,
  Checkbox,
  IconButton,
  useTheme,
  InputAdornment,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useCallback, useMemo, useRef, useState} from 'react';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';
import moment from 'moment';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function getMultiComparator (orderBy, index= 0){
  return function (a,b){
    const comparator = getComparator(orderBy[index]['order'], orderBy[index]['field']);
    const result = comparator(a,b);
    if(!result){
      const nextIndex = index + 1;
      if(nextIndex<orderBy.length){
        return getMultiComparator(orderBy, nextIndex)(a,b);
      }
    }
    return result;
  }
}
function FilterField(props){
  const {name, filter, onChange, render} = props;
  const handleChange = useCallback(function(){
    onChange.apply(this, [name, ...arguments]);
  },[name]);
  return render(filter[name], handleChange)
}
function TableHeaders(props){
  const [draggedColumn,setDraggedColumn] = useState(null);
  let mouseX = useRef().current;
  const handleDragStart = useCallback((column, event)=>{
    mouseX = event.clientX;
    setDraggedColumn({column, width: event.target.offsetWidth});
  },[]);
  const handleDragOver = useCallback((column, event)=>{
    if(draggedColumn.column !== column){
      const index = props.columns.indexOf(draggedColumn.column);
      const nexIndex = props.columns.indexOf(column);
      if((nexIndex>index && event.clientX>mouseX) || (nexIndex<index && event.clientX<mouseX)){
        const columns = props.columns.slice();
        columns[index] = column;
        columns[nexIndex] = draggedColumn.column;
        props.onColumnsReorder(columns);
      }
    }
    mouseX = event.clientX;
  },[draggedColumn, props.columns, props.onColumnsReorder]);
  const handleDragEnd = useCallback((event)=>{
    setDraggedColumn(null);
  },[]);
  return (
    <>
      {props.columns.map(column=>(
        <TableHeader
          key={column.field}
          columns={props.columns}
          column={column}
          orderBy={props.orderBy}
          onOrderByChange={props.onOrderByChange}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        />
      ))}
    </>
  );
}
function TableHeader(props){
  const orderByIndex = props.orderBy.findIndex(({field})=>field===props.column.field);
  const handleTableSortLabelClick = useCallback(event=>{
    const orderBy = props.orderBy.slice();
    const index = orderBy.findIndex(item=>item.field===props.column.field);
    if(~index){
      if(orderBy[index]['order']==='asc'){
        if(event.ctrlKey){
          orderBy.splice(index,1, {field: props.column.field, order: 'desc'});
        }else{
          orderBy.splice(0, orderBy.length, {field: props.column.field, order: 'desc'});
        }
      }else{
        orderBy.splice(index, 1);
      }
    }else{
      if(event.ctrlKey){
        orderBy.push({field: props.column.field,order:'asc'});
      }else{
        orderBy.splice(0,orderBy.length,{field: props.column.field,order:'asc'});
      }
    }
    props.onOrderByChange(orderBy);
  },[props.column.field,props.orderBy,props.onOrderByChange]);
  const handleDragStart = useCallback(event=>{
    props.onDragStart(props.column, event);
  },[props.onDragStart, props.column]);
  const handleDragOver = useCallback(event=>{
    props.onDragOver(props.column, event);
  },[props.onDragOver, props.column]);
  const handleDrop = useCallback(event=>{
    props.onDrop(event);
  },[props.onDrop]);
  const handleDragEnd = useCallback(event=>{
    props.onDragEnd(event);
  },[props.onDragEnd]);
  return (
    <TableCell
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
    >
      <Badge
        badgeContent={orderByIndex+1}
        invisible={!~orderByIndex || props.orderBy.length<2}
      >
        <TableSortLabel active={!!~orderByIndex} direction={~orderByIndex?props.orderBy[orderByIndex]['order']:'asc'} onClick={handleTableSortLabelClick}>
          {props.column.headerName}
        </TableSortLabel>
      </Badge>
    </TableCell>
  );
}
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
function DateRange(props){
  const handleChangeFrom = useCallback(from=>{
    props.onChange({},{
      from,
      to: props.value.to,
    });
  },[props.onChange,props.value.to]);
  const handleChangeTo = useCallback(to=>{
    props.onChange({},{
      from: props.value.from,
      to,
    });
  },[props.onChange,props.value.from]);
  const handleClearFrom = useCallback(()=>{
    props.onChange({},{
      from: null,
      to: props.value.to,
    });
  },[props.onChange,props.value.to]);
  const handleClearTo = useCallback(()=>{
    props.onChange({},{
      from: props.value.from,
      to: null,
    });
  },[props.onChange,props.value.from]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePicker label="from" clearable onChange={handleChangeFrom} value={props.value.from} renderInput={(params) => <TextField {...params} fullWidth InputProps={{
          endAdornment: <>{params.InputProps.endAdornment}<InputAdornment position="end"><IconButton onClick={handleClearFrom}><HighlightOffIcon/></IconButton></InputAdornment></>,
        }} />}/>
      </Grid>
      <Grid item xs={6}>
        <DatePicker label="to" clearable onChange={handleChangeTo} value={props.value.to} renderInput={(params) => <TextField {...params} fullWidth InputProps={{
          endAdornment: <>{params.InputProps.endAdornment}<InputAdornment position="end"><IconButton onClick={handleClearTo}><HighlightOffIcon/></IconButton></InputAdornment></>,
        }}/>}/>
      </Grid>
    </Grid>
  );
}
DateRange.defaultProps = {
  value: {
    from: null,
    to: null,
  }
}
const selectColumnOptions = [
  {
    field: 'ReferringPhysicianName',
    headerName: 'Referring Physician Name'
  },
  {
    field: 'Modality',
    headerName: 'Modality',
  },
  {
    field: 'total',
    headerName: 'Total'
  },
];
function InstanceList(props) {
  const [columns, setColumns] = useState(selectColumnOptions.slice());
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState([]);
  const [filter,setFilter] = useState({});
  const instances = useMemo(()=>{
    return props.instances && props.instances.flatMap(instance=>{
      let ReferringPhysicianName = instance.ReferringPhysicianName;
      if(ReferringPhysicianName === undefined || !ReferringPhysicianName.length){
        ReferringPhysicianName = ['Empty Name'];
      }
      return _.uniq(ReferringPhysicianName).map(ReferringPhysicianNameValue=>{
        const newInstance = _.cloneDeep(instance);
        newInstance.ReferringPhysicianName = ReferringPhysicianNameValue;
        if(typeof newInstance.Modality !== 'string'){
          newInstance.Modality = 'Wrong modality';
        }
        return newInstance;
      });
    });
  },[props.instances]);
  const filteredInstances = useMemo(()=>{
    return instances && instances.filter(instance=>{
      for(let filter_name in filter){
        const filter_value = filter[filter_name];
        let field_value = String(instance[filter_name]);
        if(Array.isArray(filter_value) && filter_value.length && !filter_value.includes(field_value)){
          return false;
        }else if('from' in filter_value && 'to' in filter_value){

          if(moment.isMoment(filter_value.from) && moment.isMoment(filter_value.to)){
            if(!moment(field_value).isBetween(filter_value.from, filter_value.to, 'day', '[]')){
              return false;
            }
          }else if (moment.isMoment(filter_value.from)){
            if(!moment(field_value).isSameOrAfter(filter_value.from, 'day')){
              return false;
            }
          }else if(moment.isMoment(filter_value.to)){
            if(!moment(field_value).isSameOrBefore(filter_value.to, 'day')){
              return false;
            }
          }
        }
      }
      return true;
    });
  },[instances, filter]);

  const rows = useMemo(()=>{
    const rows = filteredInstances && filteredInstances.reduce((rows, instance)=>{
      const row_data = columns.map(({field})=>field).reduce((row_data,field)=>{
        if(field in instance){
          row_data[field] = instance[field];
        }
        return row_data;
      },{});
      let row = rows.find(row=>{
        return Object.entries(row_data).every(([field, value])=>row[field]===value)
      });
      if(!row){
        row = Object.assign(row_data, {key: rows.length, total: new Set()});
        rows.push(row);
      }
      row.total.add(instance['SOPInstanceUID']);
      return rows;
    },[]);
    if(rows){
      rows.forEach(row=>row.total = row.total.size);
    }
    return rows;
  },[filteredInstances,columns]);
  const sortedRows = useMemo(()=>{
    const sortedRows = rows && rows.slice();
    if(sortedRows && orderBy.length){
      sortedRows.sort(getMultiComparator(orderBy));
    }
    return sortedRows;
  },[rows,orderBy]);

  const handleSelectColumnsChange = useCallback(event=>{
    const columns = event.target.value;
    const missingOrderBy = orderBy.filter(({field})=>!columns.some(column=>column.field===field));
    if(missingOrderBy.length){
      setOrderBy(orderBy.filter(orderBy=>!missingOrderBy.includes(orderBy)));
    }
    setPage(0);
    setColumns(columns);
  }, [orderBy]);
  const handleChangePage = useCallback((event, newPage)=>{
    setPage(newPage);
  }, []);
  const handleChangeRowsPerPage = useCallback(event=>{
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  },[]);
  const handleFilterFieldChange = useCallback((name, event, value)=>{
    setPage(0);
    setFilter(prevState=>({...prevState, [name]: value}));
  },[]);

  const [idSuffix] = useState(_.uniqueId);
  return (
    <Stack spacing={2}>
      <TextField
        select
        SelectProps={{
          multiple: true,
          renderValue: selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(({field, headerName}) => (
                <Chip key={field} label={headerName} />
              ))}
            </Box>
          ),
        }}
        label="Select Columns"
        value={columns}
        onChange={handleSelectColumnsChange}
      >
        {selectColumnOptions.map(column=>(
          <MenuItem key={column.field} value={column}>
            <Checkbox checked={columns.indexOf(column) > -1} />
            <ListItemText primary={column.headerName} />
          </MenuItem>
        ))}
      </TextField>
      <Box component="fieldset" sx={{p: 2}}>
        <legend>
          Filters
        </legend>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FilterField
              name="ReferringPhysicianName"
              filter={filter}
              onChange={handleFilterFieldChange}
              render={(value, handleChange)=>(
                <Autocomplete
                  multiple
                  renderInput={params=><TextField {...params} label="Referring Physician Name"/>}
                  options={instances ? _.uniq(instances.flatMap(item=>item.ReferringPhysicianName)):[]}
                  value={value || []}
                  onChange={handleChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FilterField
              name="Modality"
              filter={filter}
              onChange={handleFilterFieldChange}
              render={(value, handleChange)=>(
                <Autocomplete
                  multiple
                  renderInput={params=><TextField {...params} label="Modalities"/>}
                  options={instances ? _.uniq(instances.map(item=>item.Modality)):[]}
                  value={value || []}
                  onChange={handleChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FilterField
              name="StudyDate"
              filter={filter}
              onChange={handleFilterFieldChange}
              render={(value, handleChange)=>(
                <DateRange value={value} onChange={handleChange}/>
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <colgroup>
            {columns.map(column=>(
              <col key={column.field}/>
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableHeaders
                columns={columns}
                onColumnsReorder={setColumns}
                orderBy={orderBy}
                onOrderByChange={setOrderBy}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row=>(
              <TableRow key={row.key}>
                {columns.map(({field})=>(
                  <TableCell key={field}>
                    {row[field]}
                  </TableCell>
                ))}
              </TableRow>
            )):(
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Skeleton />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {sortedRows && (
        <TablePagination
          rowsPerPageOptions={[2, 5, 10, 25]}
          component="div"
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </Stack>
  );
}

export default InstanceList;