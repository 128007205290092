import MainLayout from '../layouts/MainLayout';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
} from '@mui/material';
import withInstances from '../components/withInstances';
import InstanceList from './DashboardRoute/InstanceList';
import ModalityAmount from './DashboardRoute/ModalityAmount';

function DashboardRoute(props) {
  const {instances} = props;
  return(
    <MainLayout>
      <Grid container spacing={1}>
        <Grid item>
          <Card>
            <CardHeader title="Modalities" />
            <CardContent
              sx={{
                width: 300,
              }}
            >
              <ModalityAmount instances={instances}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Modality List"/>
            <CardContent>
              <InstanceList instances={instances}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default withInstances(DashboardRoute);
