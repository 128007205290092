import {
  Backdrop,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  SvgIcon,
  Toolbar,
} from '@mui/material';
import { ReactComponent as Logo} from '../../assets/centaur_flower_circ.svg';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
const DRAWER_WIDTH_CLOSED = 66;
const DRAWER_WIDTH_OPENED = 265;
const DrawerContent = styled('div', {
  name: 'Drawer',
  slot: 'Content'
})({
  position: 'fixed',
  width: DRAWER_WIDTH_CLOSED,
  height: '100vh',
  overflowX: 'hidden',
  backgroundColor:'#3f4d67',
  color:'#a9b7d0',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: 'auto 1fr',
  transition: 'width .3s ease-in-out',
  zIndex: 1,
  ':hover': {
    width: DRAWER_WIDTH_OPENED,
    '& + .MuiBackdrop-root': {
      display: 'block'
    }
  },
});
const DrawerRoot = styled('div', {
  name: 'Drawer',
  slot: 'Root'
})({
  '& + *': {
    marginLeft: DRAWER_WIDTH_CLOSED
  },
})

function Drawer(props) {
  return (
    <DrawerRoot>
      <DrawerContent>
        <Toolbar disableGutters>
          <SvgIcon component={Logo} inheritViewBox sx={{
            width:'2em',
            height:'2em',
            p: 1
          }}/>
        </Toolbar>
        <List sx={{
          overflow: 'hidden auto',
          '&::-webkit-scrollbar': {
            width: 5,
          },
          ':hover::-webkit-scrollbar-thumb':{
            borderRadius: 1,
            backgroundColor: '#a7a7a7',
          },
        }}>
          {props.navigation.map(({title, icon}, index) => (
            <ListItem button key={index}>
              <ListItemIcon sx={{color:"inherit"}}>
                <Icon>{icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={title} primaryTypographyProps={{noWrap: true}}/>
            </ListItem>
          ))}
        </List>
      </DrawerContent>
      <Backdrop sx={{display: 'none'}} open={true} />
    </DrawerRoot>
  );
}
Drawer.propTypes={
  navigation: PropTypes.array,
}
Drawer.defaultProps={
  navigation: [],
}

export default Drawer;
