import {Doughnut} from 'react-chartjs-2';
import {MenuItem, Skeleton, TextField, Box, Stack} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
const EMPTY_VALUE = 'Empty';
const options = {
  plugins:{
    legend:{
      display: false,
      labels: {
        generateLabels(chart) {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            const {labels: {pointStyle}} = chart.legend.options;
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);
              return {
                text: `${label} - ${data.datasets.map(({data})=>data[i]).join(', ')}`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                pointStyle: pointStyle,
                hidden: !chart.getDataVisibility(i),
                index: i
              };
            });
          }
          return [];
        }
      }
    }
  }
};
function ModalityAmount(props){
  const {instances} = props;
  const [field,setField] = useState('Modality');
  const handleFieldChange = useCallback(event=>{setField(event.target.value)},[]);
  const data = useMemo(()=>{
    if (!instances) return ;
    const data = instances.reduce((accumulator, instance) => {
      let values = instance[field];
      if(!Array.isArray(values)){
        values = [values];
      }
      if(!values.length){
        values.push(EMPTY_VALUE);
      }
      values.forEach(value=>{
        if (typeof value !== 'string'){
          value = EMPTY_VALUE
        }
        accumulator[value] = (accumulator[value] || 0) + 1;
      });
      return accumulator;
    },{});
    return {
      labels: Object.keys(data),
      datasets: [
        {
          label: '# of Votes',
          data: Object.values(data),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  }, [instances, field]);
  const [labelsData, setLabelsData] = useState(null);
  const Plugins = useMemo(()=>([{
    id: 'htmlLegend',
    afterUpdate(chart) {

      setLabelsData({
        chart,
        labels: chart.options.plugins.legend.labels.generateLabels(chart),
      });

    }
  }]),[]);
  return data ? (
    <Stack
      spacing={2}
    >
      <TextField
        select
        label="Type"
        value={field}
        onChange={handleFieldChange}
      >
        {['Modality','ReferringPhysicianName'].map(item=>(
          <MenuItem key={item} value={item}>{item}</MenuItem>
        ))}
      </TextField>
      {labelsData && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          {labelsData.labels.map(label=>(
            <Box
              key={label.index}
              sx={{
                display: 'flex',
                gap: 1
              }}
              onClick={() => {
                const chart = labelsData.chart
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                  // Pie and doughnut charts only have a single dataset and visibility is per item
                  chart.toggleDataVisibility(label.index);
                } else {
                  chart.setDatasetVisibility(label.datasetIndex, !chart.isDatasetVisible(label.datasetIndex));
                }
                chart.update();
              }}
            >
              <Box
                sx={{
                  background: label.fillStyle,
                  borderColor: label.strokeStyle,
                  borderWidth: label.lineWidth,
                  display: 'inline-block',
                  width: 20,
                  height: 20,
                }}
              />
              <Box
                sx={{
                  color: label.fontColor,
                  textDecoration: label.hidden ? 'line-through' : ''
                }}
              >
                {label.text}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Doughnut
        options={options}
        data={data}
        plugins={Plugins}
      />
    </Stack>
  ) : (
    <Skeleton variant="rectangular" width={300} height={300}/>
  );
}

export default ModalityAmount;
