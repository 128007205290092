import Drawer from '../components/Drawer';
import {AppBar, Box, Toolbar} from '@mui/material';

const navigation = [{
  icon: 'dashboard',
  title: 'Dashboard',
}].concat(Array.from({length:1}, () => ({icon: 'home', title: 'test test test'})));
function MainLayout(props) {
  return (
    <>
      <Drawer
        navigation={navigation}
      />
      <Box height="100vh" overflow="auto">
        <AppBar position="static" sx={{
          backgroundColor: 'inherit',
          boxShadow: 0,
          color: '#3f4d67',
        }}>
          <Toolbar>

          </Toolbar>
        </AppBar>
        <Box component="main" p={3}>
          {props.children}
        </Box>
      </Box>
    </>
  );
}

export default MainLayout;