import './App.css';
import {Routes, Route} from 'react-router-dom';
import DashboardRoute from './routes/DashboardRoute';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<DashboardRoute />} />
      </Routes>
    </div>
  );
}

export default App;
